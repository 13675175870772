'use strict'

const platformAppServices = require('./app-services')
const _ = require('lodash')
const {CSRF_TOKEN} = require('./constants/store')
const loggingUtils = require('./utils/loggingUtils')
const {getFedOpsLoggers, getBiLoggers, getBiLoggerFactoriesForApp} = platformAppServices.bi

const getApi = (appId, pageId, store, instanceId) => ({
    getCsrfToken: () => store.getValue(CSRF_TOKEN),
    monitoring: _.pick(platformAppServices.monitoring, ['createMonitor', 'getSessionData']),
    bi: loggingUtils.getBiSessionData(),
    biLoggerFactory: loggingUtils.getBiLoggerFactoryForApp({appId, instanceId}),
    fedOpsLoggerFactory: loggingUtils.getFedOpsLoggerFactory(),
    reportTrace: ({actionName = 'noop', tracePosition = 'none', actionDurationMs = 0, message = ''} = {}) => {
        const {pageUrl, getLoadingTime, metaSiteId} = loggingUtils.getBiSessionData()
        loggingUtils.bi.trace({
            appId,
            pageId,
            pageUrl,
            timeFromStartMs: getLoadingTime(),
            actionName,
            actionDurationMs,
            timestampMs: Date.now(),
            tracePosition,
            msid: metaSiteId,
            message
        })
    }
})

module.exports = {
    getApi,
    createMonitor: platformAppServices.monitoring.createMonitor,
    getFedOpsLoggers,
    getBiLoggers,
    getBiLoggerFactories: getBiLoggerFactoriesForApp
}
