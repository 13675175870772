'use strict'

const platformUtils = require('santa-platform-utils/dist/esm/viewer-platform-worker-api')
const _ = require('lodash')
const scriptsValidator = require('../scriptsValidator')
const workerUtils = require('../workerUtils')
const constants = require('../../constants/constants')
const scriptsHandler = require('../scriptHandler')
const loggingUtils = require('../../utils/loggingUtils')
const {bi, fedops, ACTION_NAMES} = loggingUtils
const platformServices = require('../../platformServices')
const {OPENED_EXPERIMENTS, CSRF_TOKEN} = require('../../constants/store')
const {measurePerformanceEnd, measurePerformanceStart} = require('../workerUtils')

const getArtifactVersion = (wixCodeBase, isBolt) => {
    const viewerName = isBolt ? 'bolt' : 'santa'
    const viewerVersion = wixCodeBase && _.head(wixCodeBase.match(/\d[\d.]*\d/))
    return `${viewerName}-${viewerVersion}`
}

function createBootstrapHandler({store, messageService, pubSubService}) {
    function freezeGlobals() {
        Object.freeze(WeakMap.prototype)
    }

    function importScripts(url, scriptName, {onSuccess, onFailure, onFinally} = {}) {
        const beforeLoad = Date.now()
        try {
            scriptsHandler.importScripts(url, null, scriptName, beforeLoad)
            if (_.isFunction(onSuccess)) {
                onSuccess()
            }
        } catch (err) {
            /*eslint-disable no-console*/
            console.error(`Failed to load script: ${scriptName}, url: ${url}`)
            bi.reportPlatformRenderError({
                duration: _.now() - beforeLoad,
                name: ACTION_NAMES.SCRIPT_LOAD_FAILED,
                details: JSON.stringify({
                    scriptName,
                    scriptUrl: url
                }),
                error: err.message
            })
            if (_.isFunction(onFailure)) {
                onFailure(err)
            }
        }
        if (_.isFunction(onFinally)) {
            onFinally()
        }
    }

    function loadSdk({parameters, namespacesSdkSource, externalComponentsSource, isDebug}) {
        self.openExperiments = store.getValue(OPENED_EXPERIMENTS)
        self.monitoringServices = platformServices.getApi().monitoring
        require('@wix/wixcode-sdk/js/modules/targets/initMonitoring.es6')
        self.wix = require('@wix/wixcode-sdk/js/modules/targets/wixCode.es6')
        self.wix.__INTERNAL__.initEnv(parameters)
        self.wix.__INTERNAL__.initUtilities({
            richTextUtils: platformUtils.richTextUtils,
            uriUtils: platformUtils.uriUtils,
            linkUtils: platformUtils.linkUtils,
            backgroundUtils: platformUtils.backgroundUtils,
            repeaterUtils: platformUtils.repeaterUtils,
            videoUrlsUtils: platformUtils.videoUrlsUtils,
            typeUtils: platformUtils.typeUtils,
            widgetUtils: platformUtils.widgetUtils,
            mediaSrcHandler: platformUtils.mediaSrcHandler,
            pubSubService,
            messageService
        })
        delete self.openExperiments
        delete self.monitoringServices
        loadNamespacesSdk(namespacesSdkSource)
        loadExternalComponents(externalComponentsSource)
        const sdk = self.wix
        const openExperiments = store.getValue(OPENED_EXPERIMENTS)
        if (!isDebug && _.includes(openExperiments, 'sv_cleanWorkerGlobals')) {
            delete self.wix
            delete self.postMessage
        }
        return sdk
    }

    function loadNamespacesSdk(sdkUrl) {
        importScripts(sdkUrl, 'wixcode-namespaces', {onFailure: workerUtils.throwError})
    }

    function loadwSpy(wSpyParam) {
        if (wSpyParam) {
            importScripts(constants.WSPY_LATEST_DSN, 'wspy.js', {
                onSuccess: () => {
                    self.wSpy = self.initWorkerHost && self.initWorkerHost({
                        settings: constants.wSpySettings,
                        wSpyParam
                    })
                },
                onFailure: workerUtils.throwError
            })
        }
    }

    function loadExternalComponents(sdkExternalComponentsUrl) {
        importScripts(sdkExternalComponentsUrl, 'wixcode-components', {onFailure: workerUtils.throwError})
    }

    function loadFedOpsAndBi({biSessionData, sdkParameters, wixCodeBase, isBolt, openExperiments, isDebug, pageId, isPopup}) {
        const {renderingEnv, viewMode} = sdkParameters
        const artifactVersion = getArtifactVersion(wixCodeBase, isBolt)
        const isPreview = viewMode !== 'site'
        const biSession = _.assign(
            biSessionData,
            {
                pageId,
                isServerSide: renderingEnv === 'backend',
                artifactVersion,
                isPreview,
                isPopup
            }
        )

        const {getFedOpsLoggers, getBiLoggers, getBiLoggerFactories} = platformServices
        const reportTrace = _.includes(openExperiments, 'sv_reportTrace')
        const reportPlatformFedops = reportTrace ||
            !isPopup && !biSession.isCached && biSession.pageNumber === 1

        const biStoreData = {
            biSessionData: biSession,
            isDebug,
            isPreview,
            biSampleByRequestId: _.includes(openExperiments, 'biSampleByRequestId'),
            fedopsNoSampling: _.includes(openExperiments, 'fedopsNoSampling'),
            reportTrace,
            reportPlatformFedops
        }

        loggingUtils.init(biStoreData, messageService, {getFedOpsLoggers, getBiLoggers, getBiLoggerFactories})
    }

    return function handleBootstrap(messageData, appsStore) {
        measurePerformanceStart('bootstrap')
        const {bootstrapArguments, isBolt, pageId, isPopup} = messageData
        store.setValues(bootstrapArguments)

        const {
            namespacesSdkSource,
            externalComponentsSource,
            wixCodeNamespacesAndElementorySupportSource,
            sdkParameters,
            openExperiments,
            csrfToken,
            isDebug,
            biSessionData,
            wSpyParam,
            wixCodeBase
        } = bootstrapArguments

        if (!sdkParameters) {
            throw new Error(`Could not load user code: \`sdkParameters\` has an invalid value: ${sdkParameters}`)
        }

        loadFedOpsAndBi({
            biSessionData,
            sdkParameters,
            wixCodeBase,
            isBolt: Boolean(isBolt),
            openExperiments,
            isDebug,
            pageId,
            isPopup
        })
        fedops.reportPlatformLoadStarted()

        store.setValue(OPENED_EXPERIMENTS, openExperiments)
        freezeGlobals()

        importScripts(wixCodeNamespacesAndElementorySupportSource, 'wixCodeNamespacesAndElementorySupport')

        store.setValue(CSRF_TOKEN, csrfToken)
        const applications = JSON.parse(bootstrapArguments.applications)
        scriptsValidator.validate(applications, ['id', 'url'])

        loadwSpy(wSpyParam)
        const sdk = loadSdk({parameters: sdkParameters, namespacesSdkSource, externalComponentsSource, isDebug})
        workerUtils.importModules(applications, appsStore)
        messageService.sendBootstrapMessage()
        measurePerformanceEnd('bootstrap')
        return sdk
    }
}

module.exports = createBootstrapHandler
