'use strict'

const _ = require('lodash')

module.exports = class Store {
    constructor() {
        this._store = {}
    }

    setValues(values) {
        _.assign(this._store, values)
    }
  
    pickValues(...keys) {
        return _.pick(this._store, keys)
    }

    setValue(path, value) {
        _.set(this._store, path, value)
    }

    getValue(path) {
        return _.get(this._store, path, null)
    }
}
